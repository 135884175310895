import React from "react"

/**
 * Material
 */
import { AskForm } from "../../../components/contact/casualvisit/index"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `CONTACT`, path: `contact` },
  { text: `カジュアル面談`, path: `contact/casualvisit` },
]

/**
 * Components
 */
export default function Home() {
  return <AskForm breadcrumbsData={breadcrumbsData} />
}
