import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"
import { CONTACT_INPUT_STYLE } from "./styles"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Input = styled.input`
  ${CONTACT_INPUT_STYLE}
  -webkit-appearance: none;
  &[type='date'], [type='time'] {
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      height: 60px;
    }
  }
  &::-webkit-date-and-time-value {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  &::-webkit-datetime-edit {
    padding: 0;
  }
  &::-webkit-datetime-edit-year-field {
    padding: 0;
  }
  &::-webkit-datetime-edit-month-field {
    padding: 0;
  }
  &::-webkit-datetime-edit-day-field {
    padding: 0;
  }
`

type Props = {
  value: string
  setter: Function
  isRequired?: boolean
  hasError?: boolean
}

export const ContactInputDate: React.VFC<Props> = ({value, setter, isRequired, hasError}) => {
  return (
    <Wrapper>
      <Input
        type="date"
        defaultValue={value}
        onChange={(e) => setter(e.target.value)}
        onBlur={(e) => setter(e.target.value)}
        data-is-required={!!isRequired && !value}
        data-is-error={!!hasError}
      />
    </Wrapper>
  )
}
