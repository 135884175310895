import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"
import { ContactInputMessage } from "./ContactInputMessage"
import { ContactErrorMessage } from "./ContactErrorMessage"

import { RequiredBox } from "./RequiredBox"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  margin-top: 24px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 16px 0;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  gap: 16px;
`

const Label = styled.span`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 24px;
  }
`

type Props = {
  label: String
  children: any
  isRequired?: boolean
  hasError?: boolean
  annotationMessage?: string
  errorMessage?: string
  displayed?: boolean
}

export const ContactInputGroupSub: React.VFC<Props> = ({label, children, isRequired, hasError, annotationMessage, errorMessage, displayed=true}) => {
  if (!displayed) return <></>

  return (
    <Wrapper>
      <LabelWrapper>
        <Label>{label}</Label>
      </LabelWrapper>
      {children}
      {
        // エラーがあり、エラーメッセージがないときは自動で出力する
        hasError && isRequired && !errorMessage &&
          <ContactErrorMessage text={`${label}は必須です`} />
      }
      <ContactInputMessage annotationMessage={annotationMessage} errorMessage={errorMessage} />
    </Wrapper>
  )
}
