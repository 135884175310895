import React from "react"
import styled from "styled-components"

import { CONTACT_INPUT_STYLE_BASE } from "./styles"
import { RESPONSIVE_STYLES } from "../../../AppConst"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.label`
  ${CONTACT_INPUT_STYLE_BASE}
  display: flex;
  align-items: baseline;
  width: fit-content;
  padding: 0;
  border: 2px solid transparent;
  cursor: pointer;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 0;
  }
`

const Text = styled.span`
  padding-left: 12px;
  box-sizing: border-box;
`

const Input = styled.input`
  position: relative;
  width: auto;
  :checked {
    &:before {
      border: 2px solid #61B1C1;
    }
    &:after {
      background: #61B1C1;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: calc(-50%);
    left: -4px;
    width: 24px;
    height: 24px;
    background: #FFFFFF;
    border: 2px solid #AAAAAA;
    box-sizing: border-box;
    border-radius: 12px;
  }
  &:after {
    content: '';
    position: absolute;
    top: calc(-50% + 4px);
    width: 16px;
    height: 16px;
    border-radius: 8px;
  }
`

type Props = {
  id: string
  name: string
  labels: Array<string>
  values?: Array<string>
  setter: Function
  checkedIndex?: number
  isRequired?: boolean
  hasError?: boolean
}

export const ContactInputRadioBorderlessButtons: React.VFC<Props> = ({id, name, labels, values, setter, checkedIndex, isRequired, hasError}) => {

  const setValues = (e: HTMLInputElement, setter) => {
    setter(e.value)
  }

  // 値がないときは、ラベルと同じにする
  if (!values) {
    values = labels;
  }

  return (
    <Wrapper>
      <InputsWrapper>
      {
        labels.map((label, i) => {
          return (
            <InputWrapper key={i}>
              <Label
                htmlFor={`${id}-${i}`}
                data-is-required={!!isRequired}
                data-is-error={!!hasError}
              >
                <Input
                  type="radio"
                  id={`${id}-${i}`}
                  name={name}
                  value={values[i]}
                  checked={checkedIndex === i}
                  onClick={(e) => setValues(e.currentTarget, setter)}
                  onChange={(e) => setValues(e.currentTarget, setter)}
                  onBlur={(e) => setValues(e.currentTarget, setter)}
                />
                <Text>{label}</Text>
              </Label>
            </InputWrapper>
          )
        })
      }
      </InputsWrapper>
    </Wrapper>
  )
}
