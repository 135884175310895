import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import {
  RESPONSIVE_STYLES,
  AskContactTypes,
  DATE_TYPE,
  TIME_TYPE
} from "../../../AppConst"
import { ContactInputGroup } from "../common/ContactInputGroup"
import { ContactInputGroupSub } from "../common/ContactInputGroupSub"
import { ContactInputText } from "../common/ContactInputText"
import { ContactInputDate } from "../common/ContactInputDate"
import { ContactInputTextarea } from "../common/ContactInputTextarea"
import { ContactInputRadioButtons } from "../common/ContactInputRadioButtons"
import { ContactInputRadioBorderlessButtons } from "../common/ContactInputRadioBorderlessButtons"
import { AgreedPolicy } from "../common/AgreedPolicy"
import { casualVisitFormValues, useCasualVisitFormContext } from "../../../utlis/FormValues"
import { validRequired, invalidRequired } from "../../../utlis/check"
import { contact } from "../../../data/contact"
import { ContactFormConfirmButton } from "../common/ContactFormConfirmButton"
import { ContactInputEmail } from "../common/ContactInputEmail"

/**
 * Element & Styles
 */
const Form = styled.form``

const FormNode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 96px;
`

const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const About = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 24px;
  }
`

type FormData = casualVisitFormValues
type Errors = {
  name: boolean
  email: boolean
  dateType: boolean
  date1: boolean
  dateType1: boolean
  date2: boolean
  dateType2: boolean
  date3: boolean
  dateType3: boolean
  methods: boolean
}
type Props = {}

/**
 * Component
 */
export const ContactAskForm: React.VFC<Props> = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    age: "",
    email: "",
    dateType: DATE_TYPE[0],
    date1: "",
    dateType1: TIME_TYPE[0],
    date2: "",
    dateType2: TIME_TYPE[0],
    date3: "",
    dateType3: TIME_TYPE[0],
    methods: AskContactTypes[0],
    ask: "",
  })

  const [isErrors, setIsErrors] = useState<Errors>({
    name: false,
    email: false,
    dateType: false,
    date1: false,
    dateType1: false,
    date2: false,
    dateType2: false,
    date3: false,
    dateType3: false,
    methods: false,
  })
  const [checked, setChecked] = useState(false)

  const updateFormData = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value })
    setIsErrors(_errors => ({ ..._errors, [name]: false }))
  }

  const validate = (values: FormData) => {
    setIsErrors(_errors => ({ ..._errors, name: invalidRequired(values.name) }))
    setIsErrors(_errors => ({ ..._errors, email: invalidRequired(values.email) }))
    setIsErrors(_errors => ({ ..._errors, dateType: invalidRequired(values.dateType) }))

    // 希望日時指定は、日付のチェックを行う
    if (values.dateType === DATE_TYPE[1]) {
      setIsErrors(_errors => ({ ..._errors, date1: invalidRequired(values.date1) }))
      setIsErrors(_errors => ({ ..._errors, date2: invalidRequired(values.date2) }))
      setIsErrors(_errors => ({ ..._errors, date3: invalidRequired(values.date3) }))
      setIsErrors(_errors => ({ ..._errors, dateType1: invalidRequired(values.dateType1) }))
      setIsErrors(_errors => ({ ..._errors, dateType2: invalidRequired(values.dateType2) }))
      setIsErrors(_errors => ({ ..._errors, dateType3: invalidRequired(values.dateType3) }))
    } else {
      setIsErrors(_errors => ({ ..._errors, date1: false }))
      setIsErrors(_errors => ({ ..._errors, date2: false }))
      setIsErrors(_errors => ({ ..._errors, date3: false }))
      setIsErrors(_errors => ({ ..._errors, dateType1: false }))
      setIsErrors(_errors => ({ ..._errors, dateType2: false }))
      setIsErrors(_errors => ({ ..._errors, dateType3: false }))
    }
    setIsErrors(_errors => ({ ..._errors, methods: invalidRequired(values.methods) }))
  }

  const isValid = (values: FormData) => {
    validate(values)
    return (
      validRequired(values.name)
      && validRequired(values.email)
      && (
        values.dateType === DATE_TYPE[0]
        ||
        (
          values.dateType === DATE_TYPE[1]
          && validRequired(values.date1)
          && validRequired(values.date2)
          && validRequired(values.date3)
          && validRequired(values.dateType1)
          && validRequired(values.dateType2)
          && validRequired(values.dateType3)
        )
      )
    )
  }

  const { state, setState } = useCasualVisitFormContext()

  const isStateEmpty = () => {
    for (let key in state) {
      if (state[key] !== "") return false
    }
    return true
  }

  useEffect(() => {
    if (!isStateEmpty()) setFormData({ ...state })
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // エラーがある場合、画面遷移しない
    if (!isValid(formData)) return

    // useContextの設定
    setState({ ...formData })

    navigate("./confirm")
  }

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}>
      <FormNode>

        <AboutWrapper>
          <About>当社では求職者様が本選考をご希望される前にカジュアル面談への参加を推奨しております。</About>
          <About>カジュアル面談では、当社の事業や社風、特徴を紹介させていただき、質疑応答や談話時間を設ける事で、求職者様に当社の事を知っていただく事に重きを置いております。</About>
          <About>当社からはリクルート担当者と現役の若手エンジニア、ベテランエンジニアが対応させていただきます。</About>
          <About>カジュアル面談の参加は選考査定に影響するものではございませんので、どうぞお気軽にご参加いただければ幸いです。</About>
        </AboutWrapper>

        <ContactInputGroup
          label={contact.casualvisit.name.title}
          isRequired={contact.casualvisit.name.required}
          hasError={isErrors.name}>
          <ContactInputText
            value={formData.name}
            setter={(value) => updateFormData(value, "name")}
            isRequired={contact.casualvisit.name.required}
            placeholder={"例）大阪　太郎"}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.casualvisit.age.title}
          isRequired={contact.casualvisit.age.required}>
          <ContactInputText
            value={formData.age}
            setter={(value) => updateFormData(value, "age")}
            isRequired={contact.casualvisit.age.required}
            placeholder={"例）25"}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.casualvisit.email.title}
          isRequired={contact.casualvisit.email.required}
          hasError={isErrors.email}>
          <ContactInputEmail
            value={formData.email}
            setter={(value) => updateFormData(value, "email")}
            isRequired={contact.casualvisit.email.required}
            placeholder={"例）example@nextribe.co.jp"}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.casualvisit.dateType.title}
          isRequired={contact.casualvisit.dateType.required}>
          <ContactInputRadioButtons
            name={`dateType`}
            labels={DATE_TYPE}
            setter={(value) => updateFormData(value, "dateType")}
            isRequired={contact.casualvisit.dateType.required}
            checkedIndex={DATE_TYPE.indexOf(formData.dateType)}
            hasError={isErrors.dateType}
          />

          <ContactInputGroupSub
            label={contact.casualvisit.date1.title}
            isRequired={contact.casualvisit.date1.required}
            hasError={isErrors.date1}
            displayed={formData.dateType === DATE_TYPE[1]}
            >
            <ContactInputDate
              value={formData.date1}
              isRequired={contact.casualvisit.date1.required}
              setter={(value) => updateFormData(value, "date1")}
              hasError={isErrors.date1} />
            <ContactInputRadioBorderlessButtons
              id="dateType1"
              name={"dateType1"}
              labels={TIME_TYPE}
              checkedIndex={TIME_TYPE.indexOf(formData.dateType1)}
              setter={(value) => updateFormData(value, "dateType1")}
              hasError={isErrors.dateType1} />
          </ContactInputGroupSub>

          <ContactInputGroupSub
            label={contact.casualvisit.date2.title}
            displayed={formData.dateType === DATE_TYPE[1]}
            isRequired={contact.casualvisit.date2.required}
            hasError={isErrors.date2}
            >
            <ContactInputDate
              value={formData.date2}
              isRequired={contact.casualvisit.date2.required}
              setter={(value) => updateFormData(value, "date2")}
              hasError={isErrors.date2} />
            <ContactInputRadioBorderlessButtons
              id="dateType2"
              name={"dateType2"}
              labels={TIME_TYPE}
              checkedIndex={TIME_TYPE.indexOf(formData.dateType2)}
              setter={(value) => updateFormData(value, "dateType2")}
              hasError={isErrors.dateType2} />
          </ContactInputGroupSub>

          <ContactInputGroupSub
            label={contact.casualvisit.date3.title}
            displayed={formData.dateType === DATE_TYPE[1]}
            isRequired={contact.casualvisit.date3.required}
            hasError={isErrors.date3}
            >
            <ContactInputDate
              value={formData.date3}
              isRequired={contact.casualvisit.date3.required}
              setter={(value) => updateFormData(value, "date3")}
              hasError={isErrors.date3} />
            <ContactInputRadioBorderlessButtons
              id="dateType3"
              name={"dateType3"}
              labels={TIME_TYPE}
              checkedIndex={TIME_TYPE.indexOf(formData.dateType3)}
              setter={(value) => updateFormData(value, "dateType3")}
              hasError={isErrors.dateType3} />
          </ContactInputGroupSub>
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.casualvisit.methods.title}
          isRequired={contact.casualvisit.methods.required}
          annotationMessage={contact.casualvisit.methods.annotation}>
          <ContactInputRadioButtons
            name={`methods`}
            labels={AskContactTypes}
            setter={(value) => updateFormData(value, "methods")}
            isRequired={contact.casualvisit.methods.required}
            checkedIndex={AskContactTypes.indexOf(formData.methods)}
            hasError={isErrors.methods}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.casualvisit.ask.title}
          isRequired={contact.casualvisit.ask.required}
          message={contact.casualvisit.ask.message}>
          <ContactInputTextarea
            value={formData.ask}
            setter={(value) => updateFormData(value, "ask")}
            isRequired={contact.casualvisit.ask.required}
          />
        </ContactInputGroup>

        <AgreedPolicy checked={checked} setter={setChecked} />

        <ContactFormConfirmButton isDisabled={!checked} />
      </FormNode>

    </Form>
  )
}
